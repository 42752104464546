<template>
  <div
    v-if="!hidden"
    class="toast"
    :style="[
      backgroundImageCss,
      backgroundColorCss,
      colorVariablesCss
    ]"
    :class="{
      'hidden': hidden,
      'with-button': buttons.length,
      'with-close': closeIcon
    }"
  >
    <div
      v-if="ticker.length"
      class="ticker"
    >
      <div class="track">
        <span
          v-for="(tickerItem, index) in tickerItems"
          :key="index"
          class="ticker-item"
        >
          {{ tickerItem.text }}
        </span>
      </div>
    </div>
    <div v-else-if="text.html" class="text-wrapper di di--4">
      <div class="text" v-html="nl2br(text.html)" />
      <div
        v-for="button in buttons"
        :key="button._uid"
        class="toast-button"
      >
        <Button
          v-bind="button"
          size="mini"
        />
      </div>
    </div>
    <div
      v-if="closeIcon"
      class="close-icon"
      @click="close"
    >
      <CrossIcon />
    </div>
  </div>
</template>

<script>
import ColorHexCode from '~/mixins/ColorHexCode.js'
import CrossIcon from '~/assets/icons/cross.svg'
import { nl2br } from '~/lib/helpers'

export default {
  name: 'Toast',
  components: { CrossIcon },
  mixins: [ColorHexCode],
  props: {
    text: {
      type: Object,
      required: false,
      default: null
    },
    buttons: {
      type: Array,
      required: false,
      default: () => ([])
    },
    backgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: ''
    },
    backgroundImage: {
      type: Object,
      required: false,
      default: null
    },
    ticker: {
      type: Array,
      required: false,
      default () {}
    },
    highlightedFontBackgroundColor: {
      type: String,
      required: false,
      default: ''
    },
    highlightedFontColor: {
      type: String,
      required: false,
      default: ''
    },
    highlightedFontColorHex: {
      type: String,
      required: false,
      default: ''
    },
    fontColor: {
      type: String,
      required: false,
      default: ''
    },
    fontColorHex: {
      type: String,
      required: false,
      default: ''
    },
    closeIcon: {
      type: Boolean,
      required: false,
      default: () => (false)
    },
    closeIconColor: {
      type: String,
      required: false,
      default: ''
    },
    closeIconColorHex: {
      type: String,
      required: false,
      default: ''
    },
    cookieId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      hidden: false
    }
  },
  computed: {
    backgroundImageCss () {
      return this.backgroundImage?.filename ? { backgroundImage: 'url(' + this.backgroundImage.filename + ')' } : ''
    },
    backgroundColorCss () {
      const backgroundColor = this.color(this.backgroundColor, this.backgroundColorHex)
      return backgroundColor ? { backgroundColor } : ''
    },
    colorVariablesCss () {
      return {
        '--font-color': this.fontColorHex || this.fontColor,
        '--highlighted-font-color': this.highlightedFontColorHex || this.highlightedFontColor,
        '--highlighted-font-background-color': this.highlightedFontBackgroundColor,
        '--close-icon-color': this.color(this.closeIconColor, this.closeIconColorHex)
      }
    },
    tickerItems () {
      if (Object.keys(this.ticker).length) {
        const output = []
        for (let i = 0; i < 50; i++) {
          Object.keys(this.ticker).forEach((key) => {
            output.push(this.ticker[key])
          })
        }
        return output
      } else {
        return false
      }
    }
  },
  methods: {
    nl2br,
    close () {
      this.hidden = true
      this.$cookies.set(this.cookieId, 1, {
        path: '/',
        maxAge: 604800
      })
      this.$emit('closeToast')
    }
  }
}
</script>

<style lang="scss" scoped>
.toast {
  &.hidden {
    display: none;
  }
  position: relative;
  background-size: cover;
  background-position: center center;
  min-height: 4.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  padding: .4rem 0;

  @mixin highlighted-item {
    @include p--mini;
    padding: 0.1rem 0.4rem;
    text-transform: uppercase;
    font-style: normal;
    white-space: nowrap;
    font-weight: 800;
    letter-spacing: 0.025em;
    margin: 0 0.3rem;
    color: var(--highlighted-font-color);
    background-color: var(--highlighted-font-background-color);
  }
  .text-wrapper {
    align-items: center;
    display: flex;
    height: 100%;
    .text {
      @include p--mini;
      text-align: center;
      color: var(--font-color);
      z-index: 1;
      margin: 0;
      width: 100%;
      &::v-deep {
        > br:last-of-type {
          display: none;
        }
        p {
          margin: 0.4rem 0;
          strong {
            text-transform: uppercase;
            font-weight: 800;
          }
          em {
            @include highlighted-item;
          }
          a {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &.with-button {
    .text-wrapper {
      justify-content: space-between;
      width: 100%;
      padding: 0 1.6rem;
      position: relative;
      .text {
        text-align: left;
      }
      .toast-button {
        display: flex;
        flex-shrink: 0;
        margin-left: .8rem;
      }
    }
  }

  &.with-close {
    .close-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 4.8rem;
      flex-shrink: 0;
      transition: opacity .2s ease-out;
      position: absolute;
      right: 0;
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          opacity: .5;
        }
      }
      svg {
        height: 1.2rem;
        width: 1.2rem;
        fill: var(--close-icon-color);
      }
    }
    &.with-button {
      .text-wrapper {
        padding: 0 0 0 1.6rem;
      }
      .close-icon {
        position: relative;
        right: unset;
      }
    }
  }

  /**
   * If its a ticker
   */
  .ticker {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
    width: 100vw;
    height: 50px;
    .track {
      position: absolute;
      white-space: nowrap;
      will-change: transform;
      display: flex;
      animation: marquee 20s linear infinite;
      @keyframes marquee {
        from { transform: translateX(0); }
        to { transform: translateX(-10%); }
      }
    }
    .ticker-item {
      @include highlighted-item;
      &:nth-child(even) {
        color: var(--highlighted-font-background-color);
        background-color: var(--highlighted-font-color);
      }
    }
  }
}

// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .toast {
    min-height: 7rem;
    padding: .8rem 0;
    .text-wrapper {
      padding: 0 !important;
      max-width: 600px;
      .text {
        font-size: 1.4rem;
        text-align: center !important;
        width: auto;
        &::v-deep p {
          margin: 0.4rem 0;
          em {
            font-size: 1.4rem !important;
          }
        }
      }
    }
    .ticker {
      .ticker-item {
        font-size: 1.4rem !important;
        margin: 0 .8rem;
      }
    }
    &.with-button {
      .text-wrapper {
        width: auto;
        .text {
          padding: 0 5.6rem 0 0;
        }
      }
    }
    &.with-close {
      .close-icon {
        position: absolute !important;
        right: 1.6rem !important;
        svg {
          height: 1.6rem;
          width: 1.6rem;
        }
      }
    }
  }
}
</style>
