var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.hidden)?_c('div',{staticClass:"toast",class:{
    'hidden': _vm.hidden,
    'with-button': _vm.buttons.length,
    'with-close': _vm.closeIcon
  },style:([
    _vm.backgroundImageCss,
    _vm.backgroundColorCss,
    _vm.colorVariablesCss
  ])},[(_vm.ticker.length)?_c('div',{staticClass:"ticker"},[_c('div',{staticClass:"track"},_vm._l((_vm.tickerItems),function(tickerItem,index){return _c('span',{key:index,staticClass:"ticker-item"},[_vm._v("\n        "+_vm._s(tickerItem.text)+"\n      ")])}),0)]):(_vm.text.html)?_c('div',{staticClass:"text-wrapper di di--4"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.nl2br(_vm.text.html))}}),_vm._v(" "),_vm._l((_vm.buttons),function(button){return _c('div',{key:button._uid,staticClass:"toast-button"},[_c('Button',_vm._b({attrs:{"size":"mini"}},'Button',button,false))],1)})],2):_vm._e(),_vm._v(" "),(_vm.closeIcon)?_c('div',{staticClass:"close-icon",on:{"click":_vm.close}},[_c('CrossIcon')],1):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }